import React, {useEffect, useState} from 'react';
import './App.css';
import {getFrameIndex, pad} from "./lib";
import {useFrameChange} from "./useFrameChange";

function next(current: number): number {
    if (current === 288) return 1;
    return current + 1;
}

function Images({currentFrameIndex}: { currentFrameIndex: number }) {

    const [animationOngoing, setAnimationOngoing] = useState(false);
    const [topLayerFrameIndex, setTopLayerFrameIndex] = useState(currentFrameIndex);

    const startAnimation = () => {
        console.debug("startAnimation:", {currentFrameIndex, bottomLayerFrameIndex: topLayerFrameIndex});
        if (currentFrameIndex !== topLayerFrameIndex) {
            console.debug("starting the animation");
            setAnimationOngoing(true);
        } else {
            console.debug("no animation necessary");
        }
    }

    const endAnimation = () => {
        console.debug("finishing animation");
        setAnimationOngoing(false);
        setTopLayerFrameIndex(currentFrameIndex);
        console.debug("set top layer to frame", currentFrameIndex);
    }

    useEffect(() => {
        startAnimation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFrameIndex])

    return (
        <div className="images">
            <img src={`clock-images/${pad(next(topLayerFrameIndex), 4)}.jpeg`} alt=""/>
            <img src={`clock-images/${pad(topLayerFrameIndex, 4)}.jpeg`}
                 alt=""
                 id="current"
                 className={animationOngoing ? "animation" : ""}
                 onAnimationEnd={endAnimation}
            />
        </div>
    );
}

function App() {

    const [currentFrameIndex, setCurrentFrameIndex] = useState(getFrameIndex(new Date()));

    useFrameChange(nextFrame => {
        console.debug({nextFrame});
        setCurrentFrameIndex(nextFrame);
    })

    return (
        <Images currentFrameIndex={currentFrameIndex}/>
    );
}

export default App;
