import React from "react";
import {getFrameIndex} from "./lib";

export function useFrameChange(callback: (nextFrame: number) => void) {
    // Use useRef for mutable variables that we want to persist
    // without triggering a re-render on their change
    const requestRef = React.useRef<number>();
    const previousFrameIndex = React.useRef(getFrameIndex(new Date()))

    const animate: FrameRequestCallback = () => {
        const currentFrameIndex = getFrameIndex(new Date());
        if (currentFrameIndex !== previousFrameIndex.current) {
            previousFrameIndex.current = currentFrameIndex;
            callback(currentFrameIndex);
        }
        requestRef.current = requestAnimationFrame(animate);
    }

    React.useEffect(() => {
        let current = requestAnimationFrame(animate);
        requestRef.current = current;
        return () => cancelAnimationFrame(current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Make sure the effect runs only once
}